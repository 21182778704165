export const environment = {
  env: 'staging',
  production: true,
  host: 'staging.ark7.com',
  apiHost: 'https://ark7-corp-allosaurus-staging.us-west1.ark7.com',
  authenticatedRedirectUrl: '/dashboard',
  prodHost: 'https://staging.ark7.com',
  allowedRedirectHost: ['admin.staging.ark7.com', 'staging.ark7.com'],
  gtag: {
    trackingId: 'UA-163373891-2',
    ga4TrackingId: null,
    adsTrackingId: null,
    adsConversionSignUp: null,
    adsConversionInvestment: null,
  },
  cognito: {
    Auth: {
      Cognito: {
        userPoolClientId: '7987poji324c1u086344ct1or4',
        userPoolId: 'us-west-2_HVdDIWucM',
      },
    },
  },
  allowedFeatures: [],
  clientId: '9301',
  clientSecret: 'Ue7ZaafTqHX3k8aqTD4x',
};
