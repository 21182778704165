import { A7InputModule } from '@ark7/input';
import { A7LayoutModule } from '@ark7/layout';
import { A7LoadingModule } from '@ark7/loading';
import { A7ResourcesCommonModule } from '@ark7/resources-common';
import { A7UtilsModule } from '@ark7/utils';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { NgModule } from '@angular/core';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { AuthFormTabComponent } from './auth-form-tabs/auth-form-tab/auth-form-tab.component';
import { AuthFormTabComponent2 } from './v2/auth-form-tab/auth-form-tab.component';
import { DownloadAppPopupComponent } from './v2/download-app-popup/download-app-popup.component';
import { EmailVerificationRequiredComponent } from './auth-form-tabs/email-verification-required/email-verification-required.component';
import { ForgotPasswordFormComponent } from './forgot-password-form/forgot-password-form.component';
import { InternalServerErrorComponent } from './auth-form-tabs/internal-server-error/internal-server-error.component';
import { ResetPasswordFormComponent } from './reset-password-form/reset-password-form.component';
import { SentVerificationEmailComponent } from './auth-form-tabs/sent-verification-email/sent-verification-email.component';
import { SignInFormComponent } from './sign-in-form/sign-in-form.component';
import { SignInFormComponent2 } from './v2/sign-in-form/sign-in-form.component';
import { SignOutFormComponent } from './sign-out-form/sign-out-form.component';
import { SignUpFormComponent } from './sign-up-form/sign-up-form.component';
import { SignUpFormComponent2 } from './v2/sign-up-form/sign-up-form.component';
import { SocialSignInComponent } from './social-sign-in/social-sign-in.component';
import { TosComponent } from './tos/tos.component';
import { UserRedirectComponent } from './user-redirect-component';

@NgModule({
  declarations: [
    AuthFormTabComponent,
    AuthFormTabComponent2,
    DownloadAppPopupComponent,
    EmailVerificationRequiredComponent,
    ForgotPasswordFormComponent,
    InternalServerErrorComponent,
    ResetPasswordFormComponent,
    SentVerificationEmailComponent,
    SignInFormComponent,
    SignInFormComponent2,
    SignOutFormComponent,
    SignUpFormComponent,
    SignUpFormComponent2,
    SocialSignInComponent,
    TosComponent,
    UserRedirectComponent,
  ],
  imports: [
    A7InputModule,
    A7LayoutModule,
    A7LoadingModule,
    A7ResourcesCommonModule,
    A7UtilsModule,
    CommonModule,
    FontAwesomeModule,
    MatButtonModule,
    MatCheckboxModule,
    MatInputModule,
    MatSelectModule,
    MatSnackBarModule,
    MatTabsModule,
    NgxWebstorageModule,
    ReactiveFormsModule,
    RouterModule,
  ],
  exports: [
    AuthFormTabComponent,
    AuthFormTabComponent2,
    DownloadAppPopupComponent,
    ForgotPasswordFormComponent,
    ResetPasswordFormComponent,
    SignInFormComponent,
    SignInFormComponent2,
    SignOutFormComponent,
    SignUpFormComponent,
    SignUpFormComponent2,
    SocialSignInComponent,
    TosComponent,
  ],
})
export class A7UiAuthModule {}
