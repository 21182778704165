<div [ngClass]="[
    'bg-blue-grey-9 text-white-high-emphasis center-inner v2-padding-18-b',
    topPadding,
    v2PaddingH
  ]">
  <div [ngClass]="['full-width', maxWidth]">
    <div [ngClass]="[
        sm
          ? 'flex-column-reverse-between-start full-width v2-row-gap-1'
          : 'flex-row-between-start'
      ]">
      <div [ngClass]="
          sm
            ? 'flex-column-between-center full-width'
            : 'flex-row-between-start'
        ">
        <v2-foot-drop *ngIf="showNavi && false"
                      [ngClass]="[sm ? 'full-width' : md ? 'v2-w-9' : 'v2-w-14']"
                      header="NEW"
                      [menu]="[
            { text: 'App', href: host + '/app' },
            { text: 'IRA', href: host + '/ira' }
          ]"
                      [disabled]="!sm"
                      [hideToggle]="!sm"
                      [expanded]="!sm">
        </v2-foot-drop>
        <v2-foot-drop *ngIf="showNavi"
                      [ngClass]="[
            sm
              ? 'full-width'
              : md
              ? 'v2-w-9 v2-margin-4-r'
              : 'v2-w-14  v2-margin-4-r'
          ]"
                      header="SUPPORT"
                      [menu]="[
            {
              text: 'FAQ',
              href: 'https://ark7.com/help',
              target: '_blank'
            },
            {
              text: 'Step-by-step Instructions',
              href:
                'https://ark7.com/faq-category?categoryId=665735f1c98b64d91bc97431',
              target: '_blank'
            },
            {
              text: 'Contact',
              href: host + '/contact'
            }
          ]"
                      [disabled]="!sm"
                      [hideToggle]="!sm"
                      [expanded]="!sm">
        </v2-foot-drop>
        <v2-foot-drop [ngClass]="[sm ? 'full-width' : md ? 'v2-w-9' : 'v2-w-14']"
                      header="LEGAL"
                      [menu]="[
            {
              text: 'SEC Filings',
              href:
                'https://www.sec.gov/cgi-bin/browse-edgar?company=ark7&match=&CIK=&filenum=&State=&Country=&SIC=&owner=exclude&Find=Find+Companies&action=getcompany',
              target: '_blank'
            },
            {
              text: 'Terms & Conditions',
              href: host + '/terms-and-conditions'
            },
            {
              text: 'Privacy Policy',
              href: host + '/privacy-policy'
            }
          ]"
                      [disabled]="!sm"
                      [hideToggle]="!sm"
                      [expanded]="!sm">
        </v2-foot-drop>
        <div *ngIf="showAppDisclaimer || showTermsApply"
             class="v2-max-w-42 v2-margin-1-v"
             [ngClass]="sm ? '' : 'v2-margin-5-l  v2-margin-2-r'">
          <div class="h4 white">*Disclaimer</div>
          <div *ngIf="showTermsApply"
               class="color-green-grey-3 footer-2 v2-margin-2-t">
            Your exclusive offer is valid for 30 days from the date of registration and applies only to orders under
            Open Offerings. Orders from the Trading Market are not eligible for the New Investor Bonus. <a
               lass="color-green-grey-4 underline h5"
               href="https://ark7.com/legal/promo">Terms and Conditions</a> apply.
          </div>
          <div *ngIf="showAppDisclaimer"
               class="color-green-grey-3 footer-2 v2-margin-2-t">The Ark7 Real Estate Investing App’s ranking is based on
            search
            results for ‘Ark7 Real Estate Investing’ and ‘Real Estate Investing App’ on Apple’s App Store as of August
            23, 2024. Rankings may vary by region, device, and search terms.</div>
        </div>
      </div>
      <div class="flex-scale"></div>
      <div [ngClass]="[
          sm ? 'flex-column-between-start' : 'flex-column-between-end'
        ]">
        <v2-logo [ngClass]="['v2-margin-3-b']"
                 filter="brightness(0) invert(1)"
                 [height]="sm ? '24px' : '32px'"></v2-logo>
        <div class="flex-row-between-center"
             [ngClass]="[
            xs ? 'v2-col-gap-1' : md ? 'v2-col-gap-2' : 'v2-col-gap-4'
          ]">
          <a href="https://www.facebook.com/Ark7Official"
             target="_blank">
            <img src="/assets/v2-media/Facebook.svg"
                 alt="facebook"
                 [ngClass]="['svg-white', sm ? 'v2-h-3' : 'v2-h-4']" />
          </a>
          <a href="https://twitter.com/Ark7Official"
             target="_blank">
            <img src="/assets/v2-media/Twitter.svg"
                 alt="twitter"
                 [ngClass]="['svg-white', sm ? 'v2-h-3' : 'v2-h-4']" />
          </a>
          <a href="https://www.linkedin.com/company/ark7official"
             target="_blank">
            <img src="/assets/v2-media/LinkedIn.svg"
                 alt="linkedin"
                 [ngClass]="['svg-white', sm ? 'v2-h-3' : 'v2-h-4']" />
          </a>
          <div>
            <a href="https://www.producthunt.com/posts/ark7-2?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-ark7"
               target="_blank">
              <div>
                <img src="/assets/v2-media/ProductHunt.svg"
                     alt="product hunt"
                     [ngClass]="['svg-white', sm ? 'v2-h-3' : 'v2-h-4']" />
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <hr *ngIf="!sm"
        class="v2-margin-2-t v2-margin-4-b color-green-grey-6 v2-border-1px" />
    <div class="">
      <div class="full-width flex-row-between-center v2-margin-2-b">
        <div>&copy; 2025 Ark7 Inc.</div>
        <img class="v2-h-4"
             alt="Accredited business"
             src="/assets/v2-aplus.webp" />
      </div>
      <div class="label-2 text-justify color-green-grey-3">
        <p>
          Ark7 Inc. ("Ark7") operates the
          <a href="https://ark7.com">ark7.com</a> website (the “Site”)
          and the mobile application (the “App”). Ark7 serves as manager to Ark7 Properties LLC, Ark7 Properties Plus LLC
          and Ark7 Properties AdvanceLLC, and such companies shall be collectively referred to herein as “Ark7
          Properties”. Neither Ark7 nor Ark7 Properties is a broker-dealer or an investment advisor. Dalmore Group LLC, a
          registered broker-dealer and member
          <a href="https://www.finra.org/"
             target="_blank">FINRA</a> and
          <a href="https://www.sipc.org/"
             target="_blank">SIPC</a>, located at
          525 Green Place, Woodmere, NY 11598, is the broker-dealer of record
          for Ark7, an issuer direct offering. Information on all
          FINRA-registered broker-dealers can be found on FINRA’s
          <a href="https://brokercheck.finra.org/"
             target="_blank">BrokerCheck</a>.
        </p>
        <p>
          Investing in securities involves risks, and there is always the potential of losing money when you invest in
          securities. Past performance is no guarantee of future results. Investments such as those on the Ark7 platform
          are speculative and involve substantial risks to consider before investing, outlined in the respective offering
          materials and including, but not limited to, illiquidity, lack of diversification, and complete loss of
          capital. An investment in an offering constitutes only an investment in a particular series and not in Ark7 or
          any of the underlying asset(s). Investors should carefully review the risks located in the respective offering
          materials for a more comprehensive discussion of risks.
        </p>
        <p>
          You should speak with your financial advisor, accountant and/or attorney when evaluating any offering. Neither
          Ark7 nor Dalmore makes any recommendations or provides advice about investments, and no communication, through
          the Site, the App, or in any other medium, should be construed as a recommendation for any security offered on
          or off this investment platform. The Site and/or the App may make forward-looking statements. You should not
          rely on these statements but should carefully evaluate the offering materials in assessing any investment
          opportunity, including the complete set of risk factors that are provided as part of the offering circular for
          your consideration. Find the applicable offering circular for Ark7 Properties Plus LLC is available
          <a href="https://ark7-corp-allosaurus.us-west1.ark7.com/api/v2/conversions/signal?code=DCICE"
             target="_blank"
             class="underline">here</a>, and for Ark7 Properties Advance LLC <a
             href="https://ark7-corp-allosaurus.us-west1.ark7.com/api/v2/conversions/signal?code=JDB0O"
             target="_blank"
             class="underline">here</a>.
        </p>
        <p>
          From time to time, Ark7 will seek to qualify additional series offerings of securities under regulation A. For
          offerings that have not yet been qualified, no money or other consideration is being solicited and, if sent in
          response, will not be accepted. No offer to buy securities of a particular offering can be accepted, and no
          part of the purchase price can be received, until an offering statement filed with the Securities and Exchange
          Commission (the "SEC") relating to that series has been qualified by the SEC. Any such offer may be withdrawn
          or revoked, without obligation or commitment of any kind, at any time before notice of acceptance given after
          the date of qualification by the SEC. An indication of interest involves no obligation or commitment of any
          kind.
        </p>
        <p>
          Investment overviews contained on the Site and/or the App contain summaries of the purpose and the principal
          business terms of the investment opportunities. Such summaries are intended for informational purposes only and
          do not purport to be complete, and each is qualified in its entirety by reference to the more detailed
          discussions contained in the applicable offering circular filed with the SEC. Ark7 does not offer refunds after
          an investment has been made. Please review the relevant offering materials and subscription documentation for
          more information.
        </p>
        <p>
          All securities (“Shares”) will be issued in electronic form only and will not be listed or quoted on any
          securities exchange. We expect that after a series’ offering has concluded, the Public Private Execution
          Network Alternative Trading System, or PPEX ATS (the “Secondary Trading Platform”), which is registered with
          the SEC and operated by North Capital Private Securities Corporation (“North Capital”), will be a venue
          available for the resale of such Shares through Dalmore, as a broker-dealer member of the Secondary Trading
          Platform; provided, however, any such resale of Shares will be subject to federal and state securities laws and
          the restrictions in the series’ Operating Agreement, and there can be no assurance that an active market for
          any Shares will develop on the Secondary Trading Platform, that the Secondary Trading Platform will be
          available to allow resales of Shares to residents of all states, or that the Secondary Trading Platform will be
          available at all. For these reasons, investors must be prepared to hold their Shares indefinitely.
        </p>
      </div>
    </div>
  </div>
</div>
